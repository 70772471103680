<template>
	<div class="order_wrap">
		<v-container class="fixed_wrap">
			<v-form class="form px-6" ref="authForm">
				<div class="wrap_input_confirm">
					<v-text-field
						class="intime"
						outlined
						dense
						placeholder="판매인번호 8자리 입력"
						inputmode="numeric"
						minlength="8"
						maxlength="8"
						v-mask="'########'"
						v-model="codyNo"
						@keypress.enter.prevent="goOrderStatus"
					/>
					<v-btn
						color="primary"
						height="48px"
						depressed
						dense
						class="btn_intime"
						@click="goOrderStatus"
						:disabled="isDisableBtn"
					>
						확인
					</v-btn>
				</div>
			</v-form>
		</v-container>
	</div>
</template>
<script>
/**
 * 주문진행현황
 * @Description 주문진행현황 조회 권한 확인(영업정보시스템)
 *              조직단위를 체크하여 해당 조직의 코디의 주문진행현황만 확인 할 수 있다.
 *              IT서비스기획팀(20036113)은 조직단위를 체크하지 않는다.
 */
import { salesAuthCheck } from '@/apis/order.ec.api'
import { getStringLength } from '@/utils/common.js'

export default {
	name: 'codyForm',
	data() {
		return {
			codyNo: null
		}
	},
	computed: {
		isDisableBtn() {
			return !(this.codyNo && this.codyNo.length === 8)
		}
	},
	methods: {
		getStringLength,

		goOrderStatus() {
			// 코디번호 체크, 8자리가 아니면 리턴
			if (this.codyNo === null && this.getStringLength(this.codyNo) !== 8) {
				return
			}

			// 주문진행현황 조회 권한 확인 (영업정보시스템)
			let orgCd = ''
			let userNo = ''

			const codyQuery = {
				user_no: '',
				phone_no: '',
				dept_cd: ''
			}

			// 조직코드
			if (this.$route.query.org_cd && this.$route.query.org_cd !== '') {
				orgCd = Number(this.$route.query.org_cd)
			} else {
				this.$alert({
					message:
						'잘못된 접근입니다.<br />조직코드(사업국코드)가 누락되었습니다.',
					okText: '확인'
				})
				return
			}

			// 로그인 사번
			if (orgCd !== 20036113) {
				if (this.$route.query.userNo && this.$route.query.userNo !== '') {
					userNo = this.$route.query.userNo
				} else {
					this.$alert({
						message:
							'잘못된 접근입니다.<br />접근하는 판매인사번이 누락되었습니다.',
						okText: '확인'
					})
					return
				}
			}

			// 코디번호
			if (this.codyNo === null) {
				this.$alert({
					message: '조회하려는 판매인번호 8자리를 입력해주세요.',
					okText: '확인'
				})
				return
			}

			if (orgCd === 20036113) {
				this.$log('영업S 코디폼 진입')
				// IT서비스기획팀의 경우 체크 패스하고 이동
				codyQuery.codyNo = this.codyNo
				this.$store.dispatch('common/setUserInfo', codyQuery)
				this.$router.push({ name: 'pre-contract-list' })
			} else {
				// 그외 체크
				orgCd = this.$route.query.org_cd
				salesAuthCheck(this.codyNo, orgCd, userNo)
					.then(res => {
						if (res.code === 200) {
							this.$log('res.resultObject', res.resultObject)
							const { E_FLAG, E_RETMSG } = res.resultObject
							if (E_FLAG === 'T') {
								// 성공
								codyQuery.codyNo = this.codyNo
								codyQuery.dept_cd = this.deptCd
								this.$store.dispatch('common/setUserInfo', codyQuery)
								this.$router.push({ name: 'pre-contract-list' })
							} else {
								// 실패
								this.$alert({
									message: E_RETMSG,
									okText: '확인'
								})
							}
						}
					})
					.catch(error => {
						this.$log(error)
						this.$alert({ message: error })
					})
			}
		}
	}
}
</script>
